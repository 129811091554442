import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import TourPage from '../views/TourPage.vue';
import DataPage from '../views/DataPage.vue';
import AboutUsPage from '../views/AboutUsPage.vue';
import ServicesPage from '../views/ServicesPage.vue';
import PrivacyPolicyPage from '../views/PrivacyPolicyPage.vue';
import NotFound from '../components/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/tour',
    name: 'TourPage',
    component: TourPage
  },
  {
    path: '/data',
    name: 'DataPage',
    component: DataPage
  },
  {
    path: '/about-us',
    name: 'AboutUsPage',
    component: AboutUsPage
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: ServicesPage
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { 
      top: 0,
    }
  },
})

export default router
