<template>
    <div class="pt-32 pb-24">
        <div class="container mx-auto text-white text-start p-12 rounded-md" style="background: var(--grey);">
            <h1 class="text-3xl">POLÍTICA DE PRIVACIDADE</h1>
            <br>
            
            <p class="font-light">O que é a Política de Privacidade? A Política de Privacidade é essencial para o correto e ético funcionamento da relação empresa e cliente. Em suma, questões relacionadas à privacidade dos dados pessoais e potenciais informações obtidas por nós, através de consumidores e eventuais usuários que acessem o nosso website (photour.com.br), serão analisadas e explicadas detalhadamente a seguir.</p>
            <br>
            
            <h2 class="text-xl">2&rpar; Quais informações coletamos?</h2>
            <br>
            
            <p class="font-light">
                2.1&rpar; Informações técnicas atreladas a sua conexão obtidas diretamente pelo nosso provedor, tais como: características do dispositivo de acesso, do navegador, IP (com data e hora), origem do IP, informações sobre cliques, páginas acessadas, termos de procura digitado em nossos portais, dentre outros.
            </p>
            <br>
            
            <p class="font-light">
                2.2&rpar; Informações pessoais atreladas a mensagens e comunicações com a equipe Photour (que pode ser feita através dos nossos canais oficiais de comunicação: WhatsApp, E-mail, Instagram e LinkedIn), assim como por SMS ou outras variações de chat. Tais informações e dados ficarão salvos para facilitação de comunicação e venda dos produtos para com os usuários e clientes de nossa plataforma.
            </p>
            <br>
            
            <p class="font-light">
                2.2.1&rpar; As informações pessoais supramencionadas também poderão ser angariadas de forma offline, mediante contatos presenciais com clientes - visando esclarecimentos, melhora de comunicação e divulgação de informações relevantes ao comprador.
            </p>
            <br>
            
            <h2 class="text-xl">3&rpar; Qual o motivo da coleta de informações?</h2>
            <p class="font-light">
                Existem determinados dados e informações que são coletados visando enrijecer tanto a qualidade do serviço prestado quanto do próprio funcionamento do site. Em suma, a coleta de dados é feita no patamar mínimo, ou seja, apenas o necessário para as melhoras supracitadas.
            </p>
            <br>
            
            
            <h2 class="text-xl">4&rpar; Utilização de cookies: </h2>
            
            <p class="font-light">
                A Photour.com.br utiliza cookies para facilitar o uso e melhor adaptar seu site e aplicações aos seus interesses e necessidades, bem como para auxiliar e melhorar suas estruturas e seus conteúdos.
            </p>
            <br>
            
            <p class="font-light">
                Os cookies são pequenos arquivos de texto, utilizados por websites para tornar a navegação mais eficiente. Em nosso website são utilizados os seguintes cookies: necessários; de marketing; de desempenho e funcionais. 
            </p>
            <br>
            
            <p class="font-light">
                Em suma, o cookie é um arquivo de texto que o nosso site armazena no computador do cliente usuário ou dispositivo móvel desde (quando o site é acessado, apenas). Estes cookies podem ser bloqueados a qualquer momento através do próprio navegador do usuário, ou limpar o cache para este mesmo fim. 
            </p>
            <br>
            
            <p class="font-light">
                Além dos cookies supramencionados, os cookies não essenciais também existem. Estes, por sua vez, são utilizados da forma mais mínima e irrisória possível - sendo apenas colocados visando a entrega de um melhor serviço, consoante previsto na Lei 12.965/2014 (Marco Civil da Internet) e a Lei 13.709/18 (Lei Geral de Proteção de Dados Pessoais).
            </p>
            <br>
            
            <h2 class="text-xl">5&rpar; Por quanto tempo mantemos suas informações? </h2>
            <p class="font-light">
                Durante todo período que o cliente possuir relação de contrato com a Photour, assim como durante o tempo necessário para o cumprimento das obrigações pertinentes aos fins que motivaram sua coleta.
            </p>
            <br>
            
            <p class="font-light">
                Os dados pessoais angariados pela Photour serão excluídos quando o cliente solicitar ou não forem de fato mais necessários para a entrega do produto, exceto se a manutenção do dado for expressamente autorizada por Lei ou regulação aplicável. 
            </p>
            <br>
            
            <h2 class="text-xl">6&rpar; Com quem compartilhamos seus dados e informações?</h2>
            
            <p class="font-light">
                Os dados pessoais de clientes poderão ser compartilhados com: prestadores de serviços (exclusivamente para a realização de nossos serviços e conforme instruções do cliente); anunciantes comerciais (conforme instruções e limitações do cliente); investidores  (conforme instruções e limitações do cliente); autoridades legais, conforme previsão do ordenamento jurídico vigente.
            </p>
            <br>
            
            <h2 class="text-xl">7&rpar; Direitos em relação à proteção de dados:</h2>
            
            <p class="font-light">
                A tratativa, cuidado e armazenamento dos dados fornecidos a Photour segue o previsto na Lei 12.965/2014 (Marco Civil da Internet) e a Lei 13.709/18 (Lei Geral de Proteção de Dados Pessoais). Os dados são tratados de forma sigilosa e confidencial a todos os clientes.
            </p>
            <br>
            
            <h2 class="text-xl">8&rpar; Como a Photour protege seus dados pessoais?</h2>
            <p class="font-light">
                A Photour, consoante legislação vigente e aplicável, acaba por adotar políticas de sigilo e privacidade e políticas de segurança da informação consideradas inexoravelmente adequadas para manter os dados pessoais dos clientes completamente seguros e protegidos de acessos de terceiros não autorizados ou de qualquer forma ilícita de tratamento de tais informações. 
            </p>
            <br>
            
            <p class="font-light">
                Especificamente, artifícios como Firewalls, monitoramento constante, criptografia e verificações de segurança são os métodos utilizados pela Photour para proteção dos dados e da rede. 
            </p>
            <br>
            
            <p class="font-light">
                Caso eventuais dados queiram ser alterados por parte do cliente, basta a realização de contato com a equipe nos meios de comunicação disponíveis.  
            </p>
            <br>
            
            <h2 class="text-xl">9&rpar; Alterações da Política de Privacidade da Photour:</h2>
            <p class="font-light">
                Esta Política de Privacidade pode ser alterada periodicamente, principalmente considerando potenciais mudanças estruturais da empresa ou até mesmo em face à vindouras atualizações do ordenamento jurídico vigente. De qualquer forma, todas as versões serão arquivadas para consulta de seus usuários.
            </p>
            <br>
            
            <p class="font-light">
                A aceitação desta Política de Privacidade ocorrerá quando o próprio usuário acessar o site “Photour.com.br” ou fazer o usufruto dos serviços fornecidos pela Photour. Isso indica que o usuário e/ou cliente está em consonância e acordo total em relação a forma como iremos utilizar e gerir suas informações e seus dados. 
            </p>
            <br>
            
            <h2 class="text-xl">10&rpar; Canais de atendimento</h2>
            <p class="font-light">
                Todas as eventuais dúvidas ou esclarecimentos relacionados à presente Política de Privacidade poderão ser realizadas através do e-mail contato@photour.com.br. A nossa equipe estará prontamente preparada para resolver qualquer tipo de reclamação, sanar eventuais dúvidas e absorver quaisquer sugestões, de modo que estimulamos e estamos sempre à disposição para resolução consensual entre cliente/usuário e empresa/plataforma.
            </p>
            <br>
            
            <p class="font-light">
                Esta Política de Privacidade é válida e efetiva a partir de 18 de janeiro de 2024.
            </p>
            <br>
        </div>
    </div>
</template>
