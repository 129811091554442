<template>
  <div style="background-color: var(--black)" class="pt-24 text-white relative">
    <div class="grid lg:grid-cols-2 grid-col gap-4 gap-y-8">
      <div class="center-div flex-col sm:flex-row">
        <img
          fetchpriority="low"
          class="h-full w-full sm:w-3/5 rounded"
          src="@/assets/tour-virtual.webp"
          alt="Tour virtual"
        />

        <div class="px-0 sm:px-8">
          <h2 class="text-4xl pt-4 pb-3">Tour 360</h2>
          <p class="text-xl font-light">
            Registramos o local virtualizando o imóvel.
          </p>
        </div>
      </div>

      <div class="center-div flex-col sm:flex-row">
        <img
          fetchpriority="low"
          class="h-full w-full sm:w-3/5 rounded"
          src="@/assets/tour-tags.webp"
          alt="Tour 360"
        />

        <div class="px-0 sm:px-8">
          <h2 class="text-4xl pt-4 pb-3">TAGS</h2>
          <p class="text-xl font-light">
            Adicionamos TAGS permitindo a identificação de pontos específicos.
          </p>
        </div>
      </div>

      <div class="center-div flex-col-reverse sm:flex-row">
        <div class="px-0 sm:px-8">
          <h2 class="text-4xl pt-4 pb-3">Dollhouse</h2>
          <p class="text-xl font-light">O imóvel é mapeado em 3D.</p>
        </div>

        <img
          fetchpriority="low"
          class="h-full w-full sm:w-3/5 rounded"
          src="@/assets/tour-dollhouse.webp"
          alt="Tour 3D"
        />
      </div>

      <div class="center-div flex-col-reverse sm:flex-row">
        <div class="px-0 sm:px-8">
          <h2 class="text-4xl pt-4 pb-3">Planta 3D</h2>
          <p class="text-xl font-light">
            A planta melhora a visualização do layout.
          </p>
        </div>

        <img
          fetchpriority="low"
          class="h-full w-full sm:w-3/5 rounded"
          src="@/assets/tour-blueprint.webp"
          alt="Tour digital"
        />
      </div>

      <div class="center-div flex-col sm:flex-row">
        <img
          fetchpriority="low"
          class="h-full w-full sm:w-3/5 rounded"
          src="@/assets/tour-medida.webp"
          alt="Ferramenta de medida"
        />

        <div class="px-0 sm:px-8">
          <h2 class="text-4xl pt-4 pb-3">Medidas</h2>
          <p class="text-xl font-light">
            No software também é disponibilizada a ferramenta de medição.
          </p>
        </div>
      </div>

      <div class="center-div flex-col sm:flex-row">
        <img
          fetchpriority="low"
          class="h-full w-full sm:w-3/5 rounded"
          src="@/assets/tour-photo.webp"
          alt="Foto de Pietro De Grandi na Unsplash"
        />

        <div class="px-0 sm:px-8">
          <h2 class="text-4xl pt-4 pb-3">Fotos 4K</h2>
          <p class="text-xl font-light">
            As fotos são enviadas para o cliente.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style>
.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
}
</style>
