<template>
    <div style="background-color: var(--black)" class="pt-24 text-white relative">
      <div class="grid lg:grid-cols-2 grid-col gap-4 gap-y-8">
        <div class="center-div flex-col-reverse sm:flex-row">
          <div class="px-0 sm:px-8">
            <h2 class="text-4xl pt-4 pb-3">Planta Baixa</h2>
            <p class="text-xl font-light">Planta com medidas e disposição do imóvel.</p>
          </div>
  
          <img
            fetchpriority="high"
            class="h-full w-full sm:w-3/5 rounded"
            src="@/assets/planta-baixa.webp"
            alt="Planta Baixa"
          />
        </div>
  
        <div class="center-div flex-col-reverse sm:flex-row">
          <div class="px-0 sm:px-8">
            <h2 class="text-4xl pt-4 pb-3">Modelo 3D</h2>
            <p class="text-xl font-light">
              Modelo para ser exportado em software de sua escolha.
            </p>
          </div>
  
          <img
            fetchpriority="high"
            class="h-full w-full sm:w-3/5 rounded"
            src="@/assets/modelo-3d.webp"
            alt="Modelo 3D"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  </script>
  
  <style>
  .center-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
  }
  </style>
  