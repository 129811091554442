<template>
    <div class="container mx-auto flex flex-col lg:flex-row justify-between items-center text-white py-4">
      <h2 class="font-light text-md sm:text-[1.6rem]">Faça seu orçamento!</h2>

      <div class="flex flex-col sm:flex-row justify-around sm:justify-normal items-center w-full sm:w-auto">
        <img class="invert hidden sm:block pe-2 w-6 h-6" style="height: 22px;" src="@/assets/gmail-photour.svg" alt="Email">
        <p alt="Fale conosco Photour" class="text-md sm:text-2xl font-light pe-0 lg:pe-6">contato@photour.com.br</p>
        
        <img class="invert hidden sm:block w-6 h-6" style="height: 32px;" src="@/assets/whatsapp-photour.svg" alt="Whatsapp">
        <a target="_blank" alt="Contato Photour" href="https://wa.me/5548992058568?text=Olá!" class="text-md sm:text-2xl font-light">+55 (48) 99205 8568</a>
      </div>
    </div>
</template>