<template>
    <h1 class="md:mt-32 mt-20 mb-20 pb-0 lg:text-6xl md:text-5xl text-4xl font-light text-white">Trabalhamos com imóveis reais e modelos 3D</h1>
    <div class="flex">
        <a href="https://photour.com.br/tour?t=sPG4yxdS2gc" target="_blank" class="relative w-1/2 tour-display">
            <img class="absolute object-cover h-full" src="@/assets/tour_fisico.webp" alt="Tour Fisico">
            <div class="clickable-tour absolute top-0 left-0 h-full w-full flex justify-center items-center sm:text-4xl text-3xl text-white">
                <span>Tour Real</span>
            </div>
        </a>
        <a href="https://photour.com.br/tour?t=TPMkue8pwr9" target="_blank" class="relative w-1/2 tour-display">
            <img class="absolute object-cover h-full" src="@/assets/tour_virtual.webp" alt="Tour Virtual">
            <div class="clickable-tour absolute top-0 left-0 h-full w-full flex justify-center items-center sm:text-4xl text-3xl text-white">
                <span>Tour 3D</span>
            </div>
        </a>
    </div>
</template>

<style scoped>
.clickable-tour {
    background: rgba(0, 0, 0, 0.5);
    transition: all .3s;
}

.clickable-tour span {
    transition: all .3s;
}

.clickable-tour:hover {
    background: rgba(0, 0, 0, 0.2)
}

.clickable-tour:hover > span {
    opacity: 0;
}

.tour-display {
    height: 65vh;
}

@media screen and (max-width: 996px) {
  .tour-display {
    height: 45vh;
  }
}
</style>
