<template>
    <div style="background: var(--darker-green)">
        <div class="container mx-auto">

            <div class="text-white pt-12 pb-6 flex lg:flex-row flex-col justify-between items-center">    
                <div class="flex flex-col justify-center items-center">
                    <h1 class="lg:pb-0 pb-6 text-4xl font-light">PHOTOUR</h1>
                    <div class="flex justify-start w-full">
                        <a class="pe-1" target="_blank" href="https://www.instagram.com/photour.br/" alt="Instagram">
                            <img style="height: 24px;  filter: invert(1);" src="@/assets/instagram.svg" alt="Instagram">
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/photour/about/" alt="Linkedin">
                            <img style="height: 24px; filter: invert(1);" src="@/assets/linkedin.svg" alt="Linkedin">
                        </a>
                    </div>
                </div>
                
                <div class="flex flex-col justify-center items-center lg:items-end">
                    <h2 class="text-end font-light text-lg sm:text-[1.6rem] pb-2">Faça seu orçamento!</h2>
                    
                    <div class="flex flex-col lg:flex-row">
                        <img class="invert mt-[6px] pe-2 hidden lg:block" style="height: 22px;" src="@/assets/gmail-photour.svg" alt="Email">
                        <p alt="Fale conosco" class="text-lg sm:text-2xl font-light pe-0 lg:pe-6">contato@photour.com.br</p>
                        
                        <img class="invert hidden lg:block" style="height: 32px;" src="@/assets/whatsapp-photour.svg" alt="Whatsapp">
                        <a target="_blank" alt="Contato" href="https://wa.me/5548992058568?text=Olá, gostaria de fazer um orçamento." class="text-lg sm:text-2xl font-light">+55 (48) 99205 8568</a>
                    </div>
                </div>
            </div>
            
            <hr class="h-px my-2 border-0" style="background: var(--green)">
            
            <div class="
            pb-12 pt-6 
            flex flex-col-reverse lg:flex-row justify-between 
            font-light text-white text-sm"
            >
                <p alt="CNPJ Photour">53.462.340/0001-09</p>
                <div class="flex flex-col lg:flex-row">
                    <p class="pb-2 lg:pb-0 pe-0 lg:pe-2">© 2024 Photour LTDA. Todos os direitos reservados.</p>
                    <router-link class="pb-2 lg:pb-0 underline underline-offset-2" to="/privacy-policy">Veja a política de privacidade</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
